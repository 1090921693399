import React from 'react';
import './Loader.scss';

const Loader = () => {
   return (
      <div className="loader">
         <div className="loadingio-spinner-spin-w78gatuxn9k"><div className="ldio-19se85a40mw">
            <div><div /></div><div><div /></div><div><div /></div><div><div /></div><div><div /></div><div><div /></div><div><div /></div><div><div /></div>
         </div></div>
      </div>
   );
}

export default Loader;